import { Fragment } from 'react';
import { func } from 'prop-types';
import {
    HeadingContainer,
    Title,
    Description,
    ActionContainer,
    SignUpSection,
    SignUpContainer,
    StyledButton,
} from '@/components/signup/shared.styles';

const SuccessfullyResetView = ({ onSignIn }) => (
    <Fragment>
        <SignUpSection>
            <SignUpContainer>
                <HeadingContainer>
                    <Title>Password successfully reset</Title>
                    <Description>Welcome back!</Description>
                </HeadingContainer>
                <ActionContainer>
                    <StyledButton type="primary" onClick={onSignIn}>
                        Sign In
                    </StyledButton>
                </ActionContainer>
            </SignUpContainer>
        </SignUpSection>
    </Fragment>
);

SuccessfullyResetView.propTypes = {
    onSignIn: func.isRequired,
};

export default SuccessfullyResetView;
