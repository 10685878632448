/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
    HeadingContainer,
    AvatarWrapper,
    StyledAvatar,
    ConversationText,
} from '@/components/signup/shared.styles';

function PmAlly({text}) {
    return (<AvatarWrapper>
        <StyledAvatar size={128} src='/images/woman.png' />
        <HeadingContainer>
            <ConversationText>{text}</ConversationText>
        </HeadingContainer>
    </AvatarWrapper>)
}

export default PmAlly;
