import NextHead from 'next/head';
import { string } from 'prop-types';

function BasicSEO({ title, description }) {
    return (
        <NextHead>
            <meta charSet="UTF-8" />
            <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
            />
            <title>Versational - {title}</title>
            <meta name="description" content={description} />
        </NextHead>
    );
}

BasicSEO.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
};

export default BasicSEO;
