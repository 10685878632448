import React from 'react';

function GoogleLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            fill="none"
            viewBox="0 0 25 24"
        >
            <g clipPath="url(#clip0)">
                <path
                    fill="#4285F4"
                    d="M23.94 12.225c0-.984-.08-1.701-.253-2.445H12.459v4.438h6.59c-.133 1.102-.85 2.764-2.445 3.88l-.022.148 3.55 2.75.246.025c2.259-2.086 3.561-5.156 3.561-8.796z"
                />
                <path
                    fill="#34A853"
                    d="M12.458 23.918c3.23 0 5.94-1.063 7.92-2.897l-3.774-2.923c-1.01.704-2.365 1.195-4.146 1.195-3.162 0-5.846-2.086-6.803-4.969l-.14.012-3.692 2.857-.048.134c1.967 3.906 6.006 6.59 10.683 6.59z"
                />
                <path
                    fill="#FBBC05"
                    d="M5.656 14.324a7.362 7.362 0 01-.399-2.365c0-.824.146-1.621.385-2.365l-.006-.159-3.738-2.903-.122.059A11.97 11.97 0 00.5 11.959c0 1.927.465 3.747 1.276 5.368l3.88-3.003z"
                />
                <path
                    fill="#EB4335"
                    d="M12.458 4.624c2.246 0 3.76.97 4.624 1.78l3.375-3.295C18.385 1.183 15.687 0 12.458 0 7.781 0 3.742 2.684 1.775 6.59l3.867 3.004c.97-2.884 3.654-4.97 6.816-4.97z"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        d="M0 0H24V24H0z"
                        transform="translate(.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default GoogleLogo;
