/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useCallback, useEffect, useState } from 'react';
import { Form, Input, notification } from 'antd';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import VersationalIconWithName from '@/svg/versationalIconWithName/versationalIconWithName';
import {
    ActionContainer,
    Description,
    HeadingContainer,
    LinkButton,
    SignUpContainer,
    SignUpSection,
    StyledButton,
    StyledForm,
    StyledFormItem,
    Title,
    DescriptionLeft,
} from '@/components/login/shared.styles';
import { useRouter } from 'next/router';
import { PASSWORD_REGEX } from '@/constants/passwordRegex';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function EnterPasswordView({ email, onSignIn, onForgotPassword, emailValue }) {
    const [form] = Form.useForm();
    const loading = useSelector((rootState) => rootState.login.loading);
    const [reCaptchaLoading, setReCaptchaLoading] = useState(true);

    const router = useRouter();

    const id = router?.query?.id;

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if (executeRecaptcha) {
            setReCaptchaLoading(false);
        }
    }, [executeRecaptcha]);

    const onError = useCallback(
        (error) => {
            // 418 - I'm a teapot
            if (error?.response?.status === 418) {
                form.setFields([
                    {
                        name: 'password',
                        errors: ['Account has been deactivated.'],
                    },
                ]);
            } else if (error?.response?.status === 401) {
                form.setFields([
                    { name: 'password', errors: ['Incorrect password.'] },
                ]);
            } else {
                notification.error({
                    message: 'Error',
                    description: 'There was error during sign in process',
                });
            }
        },
        [form],
    );
    const onFinish = useCallback(
        async ({ password }) => {
            setReCaptchaLoading(true);
            const token = await executeRecaptcha('LOGIN');
            setReCaptchaLoading(false);
            onSignIn(password, token, onError, id);
        },
        [executeRecaptcha, onError, onSignIn],
    );

    const [, forceUpdate] = useState({});

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    return (
        <SignUpSection className={emailValue ? 'simple' : ''}>
            {!emailValue && <VersationalIconWithName />}
            <SignUpContainer>
                {!emailValue && <HeadingContainer>
                    <Title>Enter your password</Title>
                    <Description>
                        Password must be at least 8 characters and contain
                        an uppercase letter, lowercase letter, and number.
                    </Description>
                </HeadingContainer>}
                <DescriptionLeft bold>Log in using <b>{email}</b></DescriptionLeft>
                <ActionContainer>
                    <StyledForm
                        form={form}
                        name="signIn"
                        onFinish={onFinish}
                        autoComplete="off"
                        validateTrigger="onSubmit"
                    >
                        <StyledFormItem
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password.',
                                },
                                {
                                    pattern: PASSWORD_REGEX,
                                    message:
                                        'Password does not meet above requirements.',
                                },
                            ]}
                        >
                            <Input.Password
                                autoFocus
                                placeholder="Password"
                                size="large"
                                tabIndex={1}
                            />
                        </StyledFormItem>
                        <LinkButton type="link" onClick={onForgotPassword}>
                            Forgot your password?
                        </LinkButton>
                        <StyledFormItem shouldUpdate>
                            {() => (
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={loading || reCaptchaLoading}
                                    disabled={!form.isFieldsTouched(true)}
                                    tabIndex={2}
                                >
                                    Sign In
                                </StyledButton>
                            )}
                        </StyledFormItem>
                    </StyledForm>
                </ActionContainer>
            </SignUpContainer>
        </SignUpSection>
    );
}

EnterPasswordView.propTypes = {
    email: string.isRequired,
    onSignIn: func.isRequired,
    onForgotPassword: func.isRequired,
    emailValue: string,
};

EnterPasswordView.defaultProps = {
    emailValue: '',
};

export default EnterPasswordView;
