import { Typography, Button, Form } from 'antd';
import styled, { css } from 'styled-components';
import { GRAY_1, WHITE, DODGERBLUE, GRAY_4 } from '@/constants/colors';

const { Paragraph } = Typography;

const Flex = css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: ${GRAY_1};
`;

export const Title = styled.h2`
    margin: 0;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;

export const secondaryText = css`
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0;
`;

export const Description = styled.span`
    ${secondaryText}
`;

export const ActionContainer = styled.div`
    ${Flex}
`;

export const StyledButton = styled(Button)`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ type }) => (type === 'primary' ? WHITE : GRAY_1)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: auto;
    width: 100%;
    > svg {
        font-size: 1.5rem;
    }
    padding: 0.5rem 2rem;
`;

export const DescriptionCentered = styled.p`
    ${secondaryText}
    font-weight: ${({ bold }) => (bold ? '500' : '400')};
    align-self: center;
`;

export const DescriptionLeft = styled.p`
    ${secondaryText}
    align-self: left;
`;

export const LinkButton = styled(Button)`
    padding: 0;
`;

export const SignUpSection = styled.section`
    box-sizing: border-box;
    border-radius: 3px;
    background: ${WHITE};

    &.simple {
        width: 100%;
        display: block;

        & > div {
            padding: 0;
        }
    }
`;

export const SignUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    .ant-spin-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`;

export const SVGContainer = styled.div`
    display: flex;
    padding: 2.25rem;
    justify-content: center;
    align-items: center;
    > svg {
        max-height: 80vh;
    }
`;

export const StyledEditableParagraph = styled(Paragraph)`
    display: inline-flex;
    color: ${DODGERBLUE};
`;

export const StyledDivider = styled.div`
    color: ${GRAY_4};
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    :before,
    :after {
        content: '';
        border-top: 1px solid ${GRAY_4};
        position: absolute;
        top: 50%;
        width: 100%;
    }

    :before {
        right: 50%;
        margin-right: 1rem;
    }

    :after {
        left: 50%;
        margin-left: 1rem;
    }
`;

export const StyledForm = styled(Form)`
    ${Flex}
`;

export const StyledFormItem = styled(Form.Item)`
    margin: 0;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;
