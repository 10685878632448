export const plans = [
    {
        name: 'Basic',
        planCode: 'VB',
        price: 0,
        annualPrice: 0,
        mostPopular: false,
        includedInPlan: '',
        features: [
            'Transcription',
            'Automated Notes',
            'Email recaps',
            'Sharing',
            'Exporting',
            '5 recordings',
            'Single user',
        ]
    },
    {
        name: 'Plus',
        planCode: 'VI',
        price: 22,
        annualPrice: 220,
        mostPopular: false,
        includedInPlan: 'Everything in Free, and',
        features: [
            'Unlimited recordings',
            'Private LLM included',
            'AI-Ally Bot across multiple recordings',
            'AI generative content',
            'AI emails',
            'AI Summaries',
            'AI-Gems Insights',
            'Auto-Task Assignment',
            'Q&A Insights',
            'Playlists',
            'Calendars, CRM, MS Teams, GoogleMeet, Zoom',
            'Single user',
        ]
    },
    {
        name: 'Collab',
        planCode: 'VT',
        price: 40,
        annualPrice: 400,
        mostPopular: true,
        includedInPlan: 'Everything in Plus, and',
        features: [
            'Live Onboarding Assistance Priority Support',
            'Team dashboard',
            'AI-Ally Smart Prompt Library',
            'Team Analytics',
            'Team Collaboration Hub',
            'Team playlists',
            'Team commenting',
            'Team & guest sharing',
            'Team members',
            '5 users included',
        ]
    },
];
