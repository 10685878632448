import { useState, useEffect, useCallback } from 'react';
import { Input, Form } from 'antd';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import {
    HeadingContainer,
    Title,
    Description,
    LinkButton,
    StyledButton,
    ActionContainer,
    SignUpSection,
    SignUpContainer,
    StyledFormItem,
    StyledForm,
} from '@/components/login/shared.styles';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function ForgotPasswordView({ onSendLink, onBack }) {
    const [form] = Form.useForm();
    const loading = useSelector((rootState) => rootState.login.loading);

    const [reCaptchaLoading, setReCaptchaLoading] = useState(true);

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if (executeRecaptcha) {
            setReCaptchaLoading(false);
        }
    }, [executeRecaptcha]);

    const onError = useCallback(() => {
        form.setFields([{ name: 'email', errors: ['Account not found.'] }]);
    }, [form]);

    const onFinish = useCallback(
        async ({ email }) => {
            setReCaptchaLoading(true);
            const token = await executeRecaptcha('FORGOT_PASSWORD');
            setReCaptchaLoading(false);
            onSendLink(email, token, onError);
        },
        [executeRecaptcha, onError, onSendLink],
    );

    const [, forceUpdate] = useState({});

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    return (
        <SignUpSection>
            <SignUpContainer>
                <HeadingContainer>
                    <Title>Forgot password</Title>
                    <Description>
                        Enter your email and we will send a link for
                        resetting your password.
                    </Description>
                </HeadingContainer>
                <ActionContainer>
                    <StyledForm
                        form={form}
                        name="forgotPassword"
                        onFinish={onFinish}
                        validateTrigger="onSubmit"
                    >
                        <StyledFormItem
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email.',
                                },
                                {
                                    type: 'email',
                                    message: 'Please input correct email.',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Email"
                                size="large"
                                autoFocus
                            />
                        </StyledFormItem>
                        <StyledFormItem shouldUpdate>
                            {() => (
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={loading || reCaptchaLoading}
                                    disabled={!form.isFieldsTouched(true)}
                                >
                                    Send Link
                                </StyledButton>
                            )}
                        </StyledFormItem>
                    </StyledForm>
                </ActionContainer>
                <LinkButton type="link" onClick={onBack}>
                    Back to sign in page
                </LinkButton>
            </SignUpContainer>
        </SignUpSection>
    );
}

ForgotPasswordView.propTypes = {
    onSendLink: func.isRequired,
    onBack: func.isRequired,
};

export default ForgotPasswordView;
