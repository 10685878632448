import {
    ActionContainerTeamsHigher,
    ActionContainerPMAlly,
    DescriptionCentered,
    SignUpContainerTeams,
    SignUpSectionTeams,
    StyledButton,
    StyledFormPmAlly,
    StyledFormItemPmAlly,
    StyledDivider,
    StyledButtonSmall,
    ActionContainerTeams,
    ActionContainerTeamsExternal,
    SignUpContainerTeamsHeading,
    StyledFormItemPmAllyFull,
} from '@/components/signup/shared.styles';
import { Form, Input, Spin, Button, notification } from 'antd';
import { RollbackOutlined, ExclamationCircleFilled, CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { AUTH_PROVIDERS } from '@/constants/authProviders';
import Link from 'next/link';
import VersationalIconWithName from '@/svg/versationalIconWithName/versationalIconWithName';
import { func, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { PASSWORD_REGEX } from '@/constants/passwordRegex';
import Microsoft365 from '@/svg/microsoft365/microsoft365';
import GoogleLogin from 'react-google-login';
import Google from '@/svg/googleLogo/googleLogo';
import { googleCalendarFeatureFlag } from '@/constants/featureFlags';
import Login from '@/components/login/login';
import useSubscriptionPlans from '@/components/settings/subscriptionAndBilling/hooks/useSubscriptionPlans';
import { plans } from '@/constants/subscriptions';
import { selectPlanCode } from '@/redux/actions/signupProcessAction';
import { fetchSubscriptionPlans } from '@/redux/actions/subscriptionPlansAction';
import { NEXT_ROUTES } from '@/constants/routes';
import axios from 'axios';
import { useRouter } from 'next/router';

function TeamsChooseProviderView({
    onCreateAccount,
    urlEmail,
    onGoogleSuccessful,
    onGoogleChosen,
    onChooseProvider,
    onGoogleFailed,
    preselectedPlan,
    loginEmail,
    tokenId,
}) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loading = useSelector((rootState) => rootState.signup.loading);
    const providerInformationLoading = useSelector(
        (rootState) => rootState.signup.providerInformationLoading,
    );
    const [providerChosen, setProviderChosen] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [accountExists, setAccountExists] = useState(null);
    const [existsEmail, setExistsEmail] = useState(null);
    const [passwordValue, setPasswordValue] = useState(null);
    const [useProviderMode, setUseProviderMode] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const router = useRouter();

    const teamsAppCampaign = router?.query?.teamsAppCampaign;

    const isAiAlly = router?.asPath?.includes('/ai-ally');

    const [mergedPlans, setMergedPlans] = useState([]);

    const { subscriptionPlans, error } =
        useSubscriptionPlans();

    const onError = (errorMessage) => {
        form.setFields([{ name: 'email', errors: [errorMessage] }]);
    };

    const onFinish = ({ email, firstname, lastname, company, teamsize, password }) => {
        onCreateAccount(email, onError, `${firstname} ${lastname}`, company, teamsize, password, selectedPlan, teamsAppCampaign);
        setProviderChosen(AUTH_PROVIDERS.VERSATIONAL);
    };

    const [, forceUpdate] = useState({});

    const handleContinue = async (event, emailValue) => {
        try {
            await axios(
                NEXT_ROUTES.LOGIN_EXIST(emailValue),
            );

            setAccountExists(true);
        } catch (e) {
            if (e.response.data.detail === 'WRONG_PROVIDER') {
                setUseProviderMode(true);
                setSelectedProvider(e.response.data.current_provider);
            }
            if (e.response.data.error === 'Email does not exist') {
                setAccountExists(false);
                form.setFieldsValue({ email: emailValue });

                forceUpdate({});
            }
        }
    }

    useEffect(() => {
        if (subscriptionPlans.length === 0) {
            dispatch(fetchSubscriptionPlans());
        }

        const newPlans = plans.map(plan => {
            const matchingSubscriptionPlanMonthly = subscriptionPlans.find(subPlan => subPlan?.plan_code?.includes(plan.planCode) && subPlan?.interval_unit === 'months');
            const matchingSubscriptionPlanAnnually = subscriptionPlans.find(subPlan => subPlan?.plan_code?.includes(plan.planCode) && subPlan?.interval_unit === 'years');

            return { ...plan, subscription: matchingSubscriptionPlanMonthly, annualSubscription: matchingSubscriptionPlanAnnually };
        });

        if (localStorage) {
            const mode = localStorage.getItem('mode');
            if (mode === 'teamsapp') {
                const newPlansFiltered = newPlans.filter(plan => !plan.planCode.includes('VB'));
                setMergedPlans(newPlansFiltered);
                return;
            }
        }

        setMergedPlans(newPlans);
    }, [plans, subscriptionPlans, error]);

    useEffect(() => {
        // set selected plan to mergedPlans[].name === Teams
        const teamsPlan = mergedPlans.find(plan => plan.name === 'Collab');
        if (teamsPlan && !preselectedPlan) {
            setSelectedPlan(teamsPlan.subscription?.plan_code);
            localStorage.setItem('planCode', teamsPlan.subscription?.plan_code);
        } else if (preselectedPlan) {
            setSelectedPlan(preselectedPlan);
            localStorage.setItem('planCode', preselectedPlan);
        }
    }, [mergedPlans, subscriptionPlans, preselectedPlan]);

    useEffect(() => {
        if (selectedPlan) {
            dispatch(selectPlanCode(selectedPlan));
        }
    }, [selectedPlan]);

    useEffect(() => {
        if (loginEmail) {
            form.setFieldsValue({ email: loginEmail });
            setExistsEmail(loginEmail);
            handleContinue(null, loginEmail);
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, [loginEmail]);

    useEffect(() => {
        if (tokenId && urlEmail != null) {
            form.setFieldsValue({ email: urlEmail });
            setExistsEmail(urlEmail);
            handleContinue(null, urlEmail);
        }
    }, [tokenId, urlEmail]);

    useEffect(() => {
        if (teamsAppCampaign) {
            localStorage.setItem('teamsAppCampaign', 'true');
        }
    }, [teamsAppCampaign]);

    let scope = 'profile email';
    if (googleCalendarFeatureFlag) {
        scope +=
            ' https://www.googleapis.com/auth/calendar.events.public.readonly https://www.googleapis.com/auth/calendar.readonly';
    }

    const containerHeading = () => {
        switch (accountExists) {
            case null:
                return isAiAlly ? <>To get started, you need a<br /> Versational Account<p style={{textAlign: 'center', fontSize: '16px', marginTop: '0.75rem'}}>Please sign up via an option below</p></> : 'Log in or sign up';
            case true:
                return 'Welcome back';
            case false:
                return 'Sign up for free';
            default:
                return isAiAlly ? <>To get started, you need a<br /> Versational Account<p style={{textAlign: 'center', fontSize: '16px', marginTop: '0.75rem'}}>Please sign up via an option below</p></> : 'Log in or sign up';
        }
    }

    return (
        <SignUpSectionTeams>
            <div style={{margin: '0 auto'}}>
                <VersationalIconWithName style={{margin: '0 auto'}} />
            </div>
            <SignUpContainerTeams>

                {(accountExists !== null || useProviderMode) && !tokenId ? <Button type="primary" icon={<RollbackOutlined />} onClick={() => {
                    setAccountExists(null);
                    setUseProviderMode(false);
                    setSelectedProvider(null);
                }} style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }} /> : null}

                {selectedPlan ? <div>
                    <Spin
                        spinning={providerInformationLoading}
                        tip={`One moment while we verify your ${
                            providerInformationLoading ===
                            AUTH_PROVIDERS.MICROSOFT
                                ? 'Microsoft'
                                : 'Google'
                        } account...`}
                    >
                        {!tokenId && !useProviderMode ? <SignUpContainerTeamsHeading>{containerHeading()}</SignUpContainerTeamsHeading> : null}

                        {!tokenId && useProviderMode && selectedProvider ? <SignUpContainerTeamsHeading>{`You used ${selectedProvider.charAt(0).toUpperCase() + selectedProvider.slice(1)} to sign up.`}</SignUpContainerTeamsHeading> : null}

                        {accountExists !== true && <>
                            <ActionContainerTeamsExternal>
                                {selectedProvider === 'microsoft' || !useProviderMode ? <StyledButtonSmall
                                    loading={
                                        loading &&
                                        providerChosen === AUTH_PROVIDERS.MICROSOFT
                                    }
                                    onClick={() => {
                                        onChooseProvider(AUTH_PROVIDERS.MICROSOFT);
                                        setProviderChosen(AUTH_PROVIDERS.MICROSOFT);
                                    }}
                                >
                                    <Microsoft365 />
                                    Continue with Microsoft 365
                                </StyledButtonSmall> : null}
                                {selectedProvider === 'google' || !useProviderMode ? <GoogleLogin
                                    clientId={
                                        process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID
                                    }
                                    onSuccess={onGoogleSuccessful}
                                    onFailure={(response) => {
                                        onGoogleFailed();
                                        if (response.error !== 'popup_closed_by_user' && response.error !== 'idpiframe_initialization_failed') {
                                            notification.error({
                                                message: 'Error',
                                                description:
                                                    'There was error during signing up using Google.',
                                            });
                                        }
                                    }}
                                    prompt="select_account"
                                    cookiePolicy="single_host_origin"
                                    accessType="offline"
                                    scope={scope}
                                    responseType="code"
                                    render={(renderProps) => (
                                        <StyledButtonSmall
                                            loading={
                                                (loading &&
                                                    providerChosen ===
                                                        AUTH_PROVIDERS.GOOGLE) ||
                                                renderProps.disabled
                                            }
                                            onClick={() => {
                                                onGoogleChosen();
                                                setProviderChosen(
                                                    AUTH_PROVIDERS.GOOGLE,
                                                );
                                                renderProps.onClick();
                                            }}
                                        >
                                            <Google />
                                            Continue with Google
                                        </StyledButtonSmall>
                                    )}
                                />  : null}
                            </ActionContainerTeamsExternal>
                            {!useProviderMode ? <StyledDivider>or</StyledDivider> : null}
                        </>}
        
                        {accountExists === null && !useProviderMode ? <ActionContainerPMAlly>
                            <ActionContainerTeams>
                                <StyledFormItemPmAlly
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your email or sign up through your email provider above.',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please provide a valid email address or sign up with your email provider above.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" size="large" id="basic_email" onPressEnter={() => handleContinue(null, existsEmail)} onChange={(e) => {
                                        setExistsEmail(e.target.value);
                                    }} />
                                </StyledFormItemPmAlly>
                                <StyledButton
                                    id="auth-continue"
                                    type="primary"
                                    loading={
                                        loading &&
                                        providerChosen ===
                                            AUTH_PROVIDERS.VERSATIONAL
                                    }
                                    disabled={!existsEmail}
                                    onClick={() => handleContinue(null, existsEmail)}
                                >
                                    Continue
                                </StyledButton>
                            </ActionContainerTeams>
                        </ActionContainerPMAlly> : null}
                            
                        {accountExists === true && <ActionContainerTeamsHigher>
                            <Login emailValue={existsEmail} />
                        </ActionContainerTeamsHigher>
                        }

                        {accountExists === false && <>
                            {
                                passwordValue?.length ? (
                                    <ul style={{paddingLeft: '0', listStyleType: 'none'}}>
                                        <li style={{ color: passwordValue.length >= 8 ? 'green' : 'red' }}>
                                            {passwordValue.length >= 8 ? <CheckCircleFilled /> : <ExclamationCircleFilled />}
                                            <span style={{marginLeft: '0.5rem'}}>Password must be at least 8 characters.</span>
                                        </li>
                                        <li style={{ color: /[A-Z]/.test(passwordValue) ? 'green' : 'red' }}>
                                            {/[A-Z]/.test(passwordValue) ? <CheckCircleFilled /> : <ExclamationCircleFilled />}
                                            <span style={{marginLeft: '0.5rem'}}>Password must contain an uppercase letter.</span>
                                        </li>
                                        <li style={{ color: /[a-z]/.test(passwordValue) ? 'green' : 'red' }}>
                                            {/[a-z]/.test(passwordValue) ? <CheckCircleFilled /> : <ExclamationCircleFilled />}
                                            <span style={{marginLeft: '0.5rem'}}>Password must contain a lowercase letter.</span>
                                        </li>
                                        <li style={{ color: /\d/.test(passwordValue) ? 'green' : 'red' }}>
                                            {/\d/.test(passwordValue) ? <CheckCircleFilled /> : <ExclamationCircleFilled />}
                                            <span style={{marginLeft: '0.5rem'}}>Password must contain a number.</span>
                                        </li>
                                    </ul>
                                ) : ''
                            }
                            <ActionContainerTeamsHigher>
                                <StyledFormPmAlly
                                    form={form}
                                    name="basic"
                                    onFinish={onFinish}
                                    validateTrigger="onSubmit"
                                >
                                    <StyledFormItemPmAlly
                                        name="firstname"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your first name.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="First name" size="large" />
                                    </StyledFormItemPmAlly>

                                    <StyledFormItemPmAlly
                                        name="lastname"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your last name.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Last name" size="large" />
                                    </StyledFormItemPmAlly>

                                    <StyledFormItemPmAllyFull
                                        name="company"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter your company name.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Company Name" size="large" />
                                    </StyledFormItemPmAllyFull>
                                    
                                    <StyledFormItemPmAllyFull
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter your email or sign up through your email provider above.',
                                            },
                                            {
                                                type: 'email',
                                                message:
                                                    'Please provide a valid email address or sign up with your email provider above.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Email" size="large" />
                                    </StyledFormItemPmAllyFull>

                                    <StyledFormItemPmAllyFull
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please provide a valid password.',
                                            },
                                            {
                                                pattern: PASSWORD_REGEX,
                                                message:
                                                    'Password does not meet requirements.',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            data-testid="password input"
                                            placeholder="Password"
                                            size="large"
                                            onChange={(e) => setPasswordValue(e.target.value)}
                                        />
                                    </StyledFormItemPmAllyFull>
                                    <DescriptionCentered maxW="340px" textAlign="center" style={{marginTop: selectedPlan ? '1rem' : '0', marginBottom: '0.25rem'}}>
                                        By using Versational you agree to the <br />
                                        <Link href="https://www.versational.ai/agreements/terms-of-service">
                                            <a target="_blank">Terms of Service</a>
                                        </Link>
                                        &nbsp;and&nbsp;
                                        <Link href="https://www.versational.ai/agreements/privacy">
                                            <a target="_blank">Privacy Policy</a>
                                        </Link>
                                    </DescriptionCentered>
                                    <StyledFormItemPmAllyFull shouldUpdate style={{
                                        margin: '0 auto',
                                    }}>
                                        {() => (
                                            <StyledButton
                                                htmlType="submit"
                                                type="primary"
                                                loading={
                                                    loading &&
                                                    providerChosen ===
                                                        AUTH_PROVIDERS.VERSATIONAL
                                                }
                                                disabled={
                                                    !form.isFieldsTouched(true)
                                                }
                                            >
                                                Agree and sign up
                                            </StyledButton>
                                        )}
                                    </StyledFormItemPmAllyFull>
                                </StyledFormPmAlly>
                            </ActionContainerTeamsHigher>
                        </>}

                                    
                        
                    </Spin>

                </div> : <LoadingOutlined style={{fontSize: '2rem'}} />}
            </SignUpContainerTeams>
        </SignUpSectionTeams>
    );
}

TeamsChooseProviderView.propTypes = {
    onCreateAccount: func.isRequired,
    urlEmail: string,
    onGoogleFailed: func.isRequired,
    onGoogleChosen: func.isRequired,
    onGoogleSuccessful: func.isRequired,
    onChooseProvider: func.isRequired,
    preselectedPlan: string,
    loginEmail: string,
    tokenId: string,
};

TeamsChooseProviderView.defaultProps = {
    urlEmail: null,
    preselectedPlan: null,
    loginEmail: null,
    tokenId: null,
};

export default TeamsChooseProviderView;
