import { node } from 'prop-types';
import styled from 'styled-components';
import { BACKGROUND_COLOR } from '@/constants/colors';

const SignUpMainPMAlly = styled.main`
    display: flex;
    align-items: center;
    padding: 1rem;
    background: ${BACKGROUND_COLOR};
    min-height: 100vh;
`;

function SignUpLayoutPMAlly({ children }) {
    return <SignUpMainPMAlly>{children}</SignUpMainPMAlly>;
}

SignUpLayoutPMAlly.propTypes = { children: node.isRequired };

export default SignUpLayoutPMAlly;
