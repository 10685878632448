import { useCallback, useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
    SignUpContainerPMAlly,
    SignUpSectionPMAlly,
} from '@/components/signup/shared.styles';
import PmAlly from '@/components/PMAlly/PMAlly';

function ChoosePasswordViewBeta({ onRegister, fullname, company, teamsize, passwordVal, conversationId, selectedPlan }) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [planName, setPlanName] = useState('');
    const firstUserName = fullname?.split(' ')[0];

    const onFinish = useCallback(
        async () => {
            const token = await executeRecaptcha('SIGN_UP');
            const nameParts = fullname?.split(' ');
            const lastName = nameParts?.pop(); // Extract last name (last word)
            const firstName = nameParts?.join(' '); // Combine the remaining words as first name
            onRegister(firstName, lastName, passwordVal, token, company, teamsize, conversationId, selectedPlan);
        },
        [executeRecaptcha, onRegister],
    );

    useEffect(() => {
        onFinish();
    }, []);

    useEffect(() => {
        if (selectedPlan?.includes('VI')) {
            setPlanName('Versational Plus');
        } else if (selectedPlan?.includes('VT')) {
            setPlanName('Versational Teams');
        } else {
            setPlanName('Versational Basic');
        }
    }, [selectedPlan]);

    return (
        <SignUpSectionPMAlly>
            <div style={{margin: '0 auto'}} />
            <SignUpContainerPMAlly>
                <PmAlly text={<>Welcome aboard, {firstUserName}! <br />Please bear with me while I set up your {planName} account.</>} />
            </SignUpContainerPMAlly>
        </SignUpSectionPMAlly>
    )
}

ChoosePasswordViewBeta.propTypes = {
    onRegister: func.isRequired,
    fullname: string.isRequired,
    company: string.isRequired,
    teamsize: string,
    passwordVal: string.isRequired,
    conversationId: string.isRequired,
    selectedPlan: string.isRequired,
};

ChoosePasswordViewBeta.defaultProps = {
    teamsize: null,
};

export default ChoosePasswordViewBeta;
