import {
    SUBSCRIPTION_PLANS_FETCH_START,
    fetchSubscriptionPlansFailure,
    fetchSubscriptionPlansSuccess,
} from '@/redux/actions/subscriptionPlansAction';
import { call, put, takeLatest } from 'redux-saga/effects';

import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';

async function fetchSubscriptionPlans() {
    return axios.get(NEXT_ROUTES.SUBSCRIPTION_PLANS);
}

function* getSubscriptionPlans() {
    try {
        const { data } = yield call(fetchSubscriptionPlans);
        yield put(fetchSubscriptionPlansSuccess(data.plans));
    } catch (err) {
        yield put(fetchSubscriptionPlansFailure(err));
    }
}

export default function* zohoAddonsSaga() {
    yield takeLatest(SUBSCRIPTION_PLANS_FETCH_START, getSubscriptionPlans);
}
