/* eslint-disable jsx-a11y/tabindex-no-positive */
import {
    ActionContainer,
    DescriptionCentered,
    HeadingContainer,
    LinkButton,
    SVGContainer,
    SignUpContainer,
    SignUpSection,
    StyledButton,
    StyledDivider,
    StyledForm,
    StyledFormItem,
    Title,
} from '@/components/login/shared.styles';
import { Form, Input, Spin } from 'antd';
import { Fragment, useEffect, useState } from 'react';

import { AUTH_PROVIDERS } from '@/constants/authProviders';
import Google from '@/svg/googleLogo/googleLogo';
import GoogleLogin from 'react-google-login';
import InitialLoginPage from '@/svg/initialLoginPage/initialLoginPage';
import Link from 'next/link';
import Microsoft365 from '@/svg/microsoft365/microsoft365';
import { ROUTES } from '@/constants/routes';
import VersationalIconWithName from '@/svg/versationalIconWithName/versationalIconWithName';
import { func, string } from 'prop-types';
import { googleCalendarFeatureFlag } from '@/constants/featureFlags';
import { useSelector } from 'react-redux';

function ChooseProviderView({
    onChooseProvider,
    onNext,
    onForgotPassword,
    onGoogleSuccessful,
    onGoogleChosen,
    onGoogleFailed,
    emailValue,
}) {
    const [form] = Form.useForm();
    const loading = useSelector((rootState) => rootState.login.loading);
    const [providerChosen, setProviderChosen] = useState(null);

    const errorMessage = (email) => (
        <span>
            User does not exist in the system.{" "}
            <Link href={`/ai-ally?loginEmail=${encodeURIComponent(email)}`}>
                <a>Sign Up?</a>
            </Link>
        </span>
    );   

    const onError = () => {
        const email = form.getFieldValue('email');

        form.setFields([
            { name: 'email', errors: [errorMessage(email)] },
        ]);
    };

    const onFinish = ({ email }) => {
        onNext(email, onError);
        setProviderChosen(AUTH_PROVIDERS.VERSATIONAL);
    };

    const [, forceUpdate] = useState({});

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        if (emailValue) {
            onFinish({ email: emailValue });
        }
    }, [emailValue]);

    let scope = 'profile email';
    if (googleCalendarFeatureFlag) {
        scope +=
            ' https://www.googleapis.com/auth/calendar.events.public.readonly https://www.googleapis.com/auth/calendar.readonly';
    }

    if (emailValue?.length) {
        return null;
    }

    return (
        <Fragment>
            <SignUpSection>
                <VersationalIconWithName />
                <SignUpContainer>
                    <Spin spinning={loading && !providerChosen}>
                        <HeadingContainer>
                            <Title>Sign In</Title>
                        </HeadingContainer>
                        <ActionContainer>
                            <StyledButton
                                loading={
                                    loading &&
                                    providerChosen === AUTH_PROVIDERS.MICROSOFT
                                }
                                onClick={() => {
                                    onChooseProvider(AUTH_PROVIDERS.MICROSOFT);
                                    setProviderChosen(AUTH_PROVIDERS.MICROSOFT);
                                }}
                            >
                                <Microsoft365 />
                                Sign in with Microsoft 365
                            </StyledButton>
                            <GoogleLogin
                                clientId={
                                    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID
                                }
                                onSuccess={onGoogleSuccessful}
                                onFailure={onGoogleFailed}
                                cookiePolicy="single_host_origin"
                                accessType="offline"
                                scope={scope}
                                responseType="code"
                                render={(renderProps) => (
                                    <StyledButton
                                        loading={
                                            (loading &&
                                                providerChosen ===
                                                    AUTH_PROVIDERS.GOOGLE) ||
                                            renderProps.disabled
                                        }
                                        onClick={() => {
                                            onGoogleChosen();
                                            setProviderChosen(
                                                AUTH_PROVIDERS.GOOGLE,
                                            );
                                            renderProps.onClick();
                                        }}
                                    >
                                        <Google />
                                        Sign in with Google
                                    </StyledButton>
                                )}
                            />
                        </ActionContainer>
                        <StyledDivider>or</StyledDivider>
                        <ActionContainer>
                            <StyledForm
                                form={form}
                                name="basic"
                                onFinish={onFinish}
                                validateTrigger="onSubmit"
                            >
                                <StyledFormItem
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your email or sign in through your email provider above.',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please provide a valid email address or sign in with your email provider above.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" size="large" tabIndex={1} />
                                </StyledFormItem>
                                <LinkButton
                                    type="link"
                                    onClick={onForgotPassword}
                                >
                                    Forgot your password?
                                </LinkButton>
                                <StyledFormItem shouldUpdate>
                                    {() => (
                                        <StyledButton
                                            htmlType="submit"
                                            type="primary"
                                            loading={
                                                loading &&
                                                providerChosen ===
                                                    AUTH_PROVIDERS.VERSATIONAL
                                            }
                                            disabled={
                                                !form.isFieldsTouched(true)
                                            }
                                            tabIndex={2}
                                        >
                                            Next
                                        </StyledButton>
                                    )}
                                </StyledFormItem>
                            </StyledForm>
                        </ActionContainer>
                        <DescriptionCentered>
                            Don’t have an account?&nbsp;
                            <Link href={ROUTES.PMALLY}>
                                <a>Sign up</a>
                            </Link>
                        </DescriptionCentered>
                    </Spin>
                </SignUpContainer>
            </SignUpSection>
            <SVGContainer>
                <InitialLoginPage />
            </SVGContainer>
        </Fragment>
    );
}

ChooseProviderView.propTypes = {
    onChooseProvider: func.isRequired,
    onNext: func.isRequired,
    onForgotPassword: func.isRequired,
    onGoogleSuccessful: func.isRequired,
    onGoogleChosen: func.isRequired,
    onGoogleFailed: func.isRequired,
    emailValue: string,
};

ChooseProviderView.defaultProps = {
    emailValue: '',
};

export default ChooseProviderView;
