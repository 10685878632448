import { Fragment } from 'react';
import Link from 'next/link';
import { Input } from 'antd';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import {
    HeadingContainer,
    Title,
    Description,
    ActionContainer,
    SignUpSection,
    SignUpContainer,
    StyledButton,
    StyledForm,
    StyledFormItem,
} from '@/components/signup/shared.styles';
import { PASSWORD_REGEX } from '@/constants/passwordRegex';

const CustomLinkClickedView = ({ onNext, email }) => {
    const loading = useSelector((rootState) => rootState.login.loading);

    const onFinish = ({ password }) => {
        onNext(password);
    };

    return (
        <Fragment>
            <SignUpSection>
                <SignUpContainer>
                    <HeadingContainer>
                        <Title>Reset password</Title>
                        <Description>Enter a new password below.</Description>
                        <Description>
                            Password must be at least 8 characters and contain
                            an uppercase letter, lowercase letter, and number.
                        </Description>
                    </HeadingContainer>
                    <Link href={`mailto:${email}`}>
                        <a style={{ alignSelf: 'center' }}>{email}</a>
                    </Link>
                    <ActionContainer>
                        <StyledForm
                            name="resetPassword"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <StyledFormItem
                                name="password"
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password.',
                                    },
                                    {
                                        pattern: PASSWORD_REGEX,
                                        message:
                                            'Password does not meet requirements.',
                                    },
                                ]}
                            >
                                <Input.Password
                                    autoFocus
                                    data-testid="password input"
                                    aria-label="Password"
                                    placeholder="Password"
                                    size="large"
                                />
                            </StyledFormItem>
                            <StyledFormItem
                                name="confirm"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please confirm your password.',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Passwords do not match.',
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    aria-label="Confirm password"
                                    placeholder="Re-enter password"
                                    size="large"
                                />
                            </StyledFormItem>
                            <StyledFormItem>
                                <StyledButton
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Next
                                </StyledButton>
                            </StyledFormItem>
                        </StyledForm>
                    </ActionContainer>
                </SignUpContainer>
            </SignUpSection>
        </Fragment>
    );
};

CustomLinkClickedView.propTypes = {
    onNext: func.isRequired,
    email: string.isRequired,
};

export default CustomLinkClickedView;
