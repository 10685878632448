import { func } from 'prop-types';
import {
    HeadingContainer,
    Title,
    Description,
    LinkButton,
    SignUpSection,
    SignUpContainer,
} from '@/components/login/shared.styles';

function CheckEmailView({ onBack }) {
    return (
        <SignUpSection>
            <SignUpContainer>
                <HeadingContainer>
                    <Title>Check your email</Title>
                    <Description>
                        Instructions for resetting your password have been
                        sent to your email. If you can’t find it, check your
                        junk and spam folders.
                    </Description>
                </HeadingContainer>
                <LinkButton type="link" onClick={onBack}>
                    Back to sign in page
                </LinkButton>
            </SignUpContainer>
        </SignUpSection>
    );
}

CheckEmailView.propTypes = {
    onBack: func.isRequired,
};

export default CheckEmailView;
