import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    SUBSCRIPTION_PLANS_FETCH_START,
    SUBSCRIPTION_PLANS_FETCH_FAILURE,
    SUBSCRIPTION_PLANS_FETCH_SUCCESS,
} from '@/redux/actions/subscriptionPlansAction';

export const initialState = {
    data: [],
    status: FETCH_STATUS.IDLE,
    error: null,
};

function subscriptionPlansReducer(state = initialState, action) {
    switch (action.type) {
        case SUBSCRIPTION_PLANS_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case SUBSCRIPTION_PLANS_FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case SUBSCRIPTION_PLANS_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }

        default:
            return state;
    }
}

export default subscriptionPlansReducer;
