import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect } from 'react';
import { SUBSCRIPTION_PLANS_KEY } from '@/constants/reduxKeys';
import { getIsAuthorized } from '@/redux/selectors/authSelector';
import subscriptionPlansSaga from '@/redux/sagas/subscriptionPlansSaga';
import subscriptionPlansReducer from '@/redux/reducers/subscriptionPlansReducer';
import {
    getIsSubscriptionPlansLoading,
    getSubscriptionPlans,
    getIsSubscriptionPlansIdleState,
    getSubscriptionPlansError,
} from '@/redux/selectors/subscriptionPlansSelector';
import { fetchSubscriptionPlans } from '@/redux/actions/subscriptionPlansAction';

export default function useSubscriptionPlans() {
    const dispatch = useDispatch();

    useInjectReducer({
        key: SUBSCRIPTION_PLANS_KEY,
        reducer: subscriptionPlansReducer,
    });

    useInjectSaga({ key: SUBSCRIPTION_PLANS_KEY, saga: subscriptionPlansSaga });

    const isAuthorized = useSelector(getIsAuthorized);
    const loading = useSelector(getIsSubscriptionPlansLoading);
    const data = useSelector(getSubscriptionPlans);
    const error = useSelector(getSubscriptionPlansError);
    const isIdle = useSelector(getIsSubscriptionPlansIdleState);

    useEffect(() => {
        if (isIdle) {
            dispatch(fetchSubscriptionPlans());
        }
    }, [dispatch, isAuthorized, isIdle]);

    return {
        loading,
        subscriptionPlans: data,
        error,
    };
}
