import {
    chooseProvider,
    chooseProviderFailed,
    forgotPassword,
    getProviderInformation,
    googleChosen,
    googleSuccessful,
    moveToResetPasswordStep,
    resetPassword,
    resetToSignInPage,
    sendLink,
    signIn,
    throughEmail,
} from '@/redux/actions/loginProcessAction';
import { useDispatch, useSelector } from 'react-redux';

import CheckEmailView from '@/components/login/checkEmail/checkEmailView';
import ChooseProviderView from '@/components/login/chooseProvider/chooseProviderView';
import CustomLinkClickedView from '@/components/login/customLinkClicked/customLinkClickedView';
import EnterPasswordView from '@/components/login/enterPassword/enterPasswordView';
import ForgotPasswordView from '@/components/login/forgotPassword/forgotPasswordView';
import SuccessfullyResetView from '@/components/login/successfullyReset/successfullyResetView';
import { notification } from 'antd';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import PropTypes from 'prop-types';

function Login({ emailValue }) {
    const state = useSelector((rootState) => rootState.login);
    const dispatch = useDispatch();
    const {
        query: {
            provider: providerFromQuery,
            error,
            error_description: errorDescription,
            email,
            tokenId,
            origin,
            accessToken,
            refreshToken,
            mode,
            callback,
            displayName,
            objectId,
            tenantId,
            preferredUserName,
        },
    } = useRouter();

    useEffect(() => {
        if (providerFromQuery) {
            dispatch(
                getProviderInformation(
                    providerFromQuery,
                    email,
                    accessToken,
                    refreshToken,
                ),
            );
        }
    }, [accessToken, dispatch, email, providerFromQuery, refreshToken]);

    useEffect(() => {
        if (tokenId && email) {
            dispatch(moveToResetPasswordStep(tokenId, email));
        }
    }, [dispatch, email, tokenId]);

    useEffect(() => {
        if (mode?.length) {
            localStorage.setItem('mode', mode);
        }
        if (callback?.length) {
            localStorage.setItem('callback', callback);
        }
        if (displayName?.length) {
            localStorage.setItem('displayName', displayName);
        }
        if (objectId?.length) {
            localStorage.setItem('objectId', objectId);
        }
        if (tenantId?.length) {
            localStorage.setItem('tenantId', tenantId);
        }
        if (preferredUserName?.length) {
            localStorage.setItem('preferredUserName', preferredUserName);
        }
    }, [mode, callback, displayName, objectId, tenantId, preferredUserName]);

    useEffect(() => {
        if (error) {
            if (errorDescription) {
                notification.error({
                    message: 'Error',
                    description: errorDescription,
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Whoops! Your Sign in process has failed!',
                });
            }
        }
    }, [error, errorDescription]);

    switch (state?.type) {
        case 'CHOOSE_PROVIDER':
            return (
                <ChooseProviderView
                    onChooseProvider={(provider) =>
                        dispatch(chooseProvider(provider))
                    }
                    onGoogleChosen={() => dispatch(googleChosen())}
                    onGoogleFailed={() => dispatch(chooseProviderFailed())}
                    onGoogleSuccessful={(r) =>
                        dispatch(googleSuccessful(r, origin, mode, callback, displayName, objectId, tenantId, preferredUserName))
                    }
                    onNext={(emailPassed, onError) =>
                        dispatch(throughEmail(emailPassed, onError))
                    }
                    onForgotPassword={() => dispatch(forgotPassword())}
                    emailValue={emailValue}
                />
            );
        case 'ENTER_PASSWORD':
            return (
                <EnterPasswordView
                    email={state.provider.email}
                    onSignIn={(password, reCaptchaToken, onError, id) =>
                        dispatch(
                            signIn(password, reCaptchaToken, onError, origin, mode, callback, displayName, objectId, tenantId, preferredUserName, id),
                        )
                    }
                    onForgotPassword={() => dispatch(forgotPassword())}
                    emailValue={emailValue}
                />
            );
        case 'FORGOT_PASSWORD':
            return (
                <ForgotPasswordView
                    onSendLink={(emailPassed, reCaptchaToken, onError) =>
                        dispatch(sendLink(emailPassed, reCaptchaToken, onError))
                    }
                    onBack={() => dispatch(resetToSignInPage())}
                />
            );
        case 'CHECK_EMAIL':
            return (
                <CheckEmailView onBack={() => dispatch(resetToSignInPage())} />
            );

        case 'CUSTOM_LINK_CLICKED':
            return (
                <CustomLinkClickedView
                    email={state.provider.email}
                    onNext={(password) => dispatch(resetPassword(password))}
                />
            );

        case 'SUCCESSFULLY_RESET':
            return (
                <SuccessfullyResetView
                    onSignIn={() => dispatch(resetToSignInPage())}
                />
            );
        default:
            return null;
    }
}

Login.propTypes = {
    emailValue: PropTypes.string,
};

Login.defaultProps = {
    emailValue: null,
};

export default Login;
